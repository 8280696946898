import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Header, Footer, LP, Commerce, Term } from './Components';

function App() {
  return (
    <Router>
      <>
        <Header/>
        <Routes className="vh-100 px-0">
          <Route path="/" element={<LP />} />
          <Route path="/commerce/" element={<Commerce />} />
          <Route path="/term/" element={<Term />} />
        </Routes>
        <Footer/>
      </>
    </Router>
  );
}

export default App;
