import React from 'react';
import { Container, Table } from 'react-bootstrap';

function Commerce() {
  return (
    <Container className='p-3'>
        <h2 className='h2-design my-3'>特定商取引法に基づく表記</h2>
        <Table striped bordered>
            <tbody>
                <tr>
                    <th className='p-3'>販売業者</th>
                    <td className='p-3'>株式会社ロキア</td>
                </tr>
                <tr>
                    <th className='p-3'>所在地</th>
                    <td className='p-3'>〒813-0017 福岡県福岡市東区香椎照葉1-1-37</td>
                </tr>
                <tr>
                    <th className='p-3'>電話番号</th>
                    <td className='p-3'>092-516-9028<br />受付時間 10:00-16:00（土日祝を除く）</td>
                </tr>
                <tr>
                    <th className='p-3'>メールアドレス</th>
                    <td className='p-3'>info@rokia.co.jp</td>
                </tr>
                <tr>
                    <th className='p-3'>運営統括責任者</th>
                    <td className='p-3'>城間 紀子</td>
                </tr>
                <tr>
                    <th className='p-3'>交換および返品</th>
                    <td className='p-3'>
                        調理には万全の注意を払っておりますが、万一商品に不備があった場合は、商品到着後、直ちに公式LINEまたはメールからご連絡ください。<br />
                        尚、商品の性質上、お客様のご都合によるキャンセル、返品、交換はお受けいたしかねますのでご了承ください。
                    </td>
                </tr>
                <tr>
                    <th className='p-3'>引渡時期</th>
                    <td className='p-3'>注文当日16：00～21：00または予約した日の16：00～21：00</td>
                </tr>
                <tr>
                    <th className='p-3'>お支払い方法</th>
                    <td className='p-3'>クレジットカードまたは国内の銀行振込</td>
                </tr>
                <tr>
                    <th className='p-3'>販売価格</th>
                    <td className='p-3'>
                        月額の場合：6,600円または13,200円<br />
                        都度購入の場合：1,320円または660円
                    </td>
                </tr>
            </tbody>
        </Table>
    </Container>
  );
}

export default Commerce;