import { Container, Navbar } from 'react-bootstrap';

function Header() {
  return (
      <Navbar className="bg-header">
          <Container>
            <Navbar.Brand href="/" className='mx-auto'>
              <img
                alt=""
                src="/foods-fit_logo.png"
                width="240"
                className="d-inline-block align-top"
              />{' '}
            </Navbar.Brand>
          </Container>
      </Navbar>
  );
}

export default Header;