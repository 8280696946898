import { Container, Navbar, Nav } from 'react-bootstrap';
function Footer() {
    return (
        <Navbar className="bg-header">
            <Container>
                <Nav>
                    <Navbar.Brand href="/">
                        <img
                        alt=""
                        src="/foods-fit_logo.png"
                        width="120"
                        className="d-inline-block align-top"
                        />
                    </Navbar.Brand>
                    <Nav.Item> 
                        <Nav.Link href="https://rokia.co.jp/">運営会社</Nav.Link>
                    </Nav.Item>
                    <Nav.Item> 
                        <Nav.Link href="/term">利用規約</Nav.Link>
                    </Nav.Item>   
                    <Nav.Item> 
                        <Nav.Link href="/commerce">特定商取引法に基づく表示</Nav.Link>
                    </Nav.Item>   
                </Nav>
            </Container>
        </Navbar>
    );
  }

export default Footer;