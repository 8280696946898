import React from 'react';
import { Container, Row, Col, Table, Card, Badge } from 'react-bootstrap';

function LP() {
    const iframeSrc = "https://calendar.google.com/calendar/embed?src=c_ff508cd58840f375db42da0b848c9a7ba1fabaeaa4a34ca463488f228de6020f%40group.calendar.google.com&ctz=Asia%2FTokyo&showTitle=0&mode=AGENDA";
    return (
    <Container fluid className='p-0'>
        <div id='first_view' className='lp-bg'>
            <a href='https://lin.ee/VDQnQiA' rel="noopener noreferrer">
                <img src="/lp_image/pc_top.png" className='w-100 d-md-block d-none' alt="ワンタップで自分好みの食事が届くMeal-Fit" />
                <img src="/lp_image/sp_top.png" className='w-100 d-md-none' alt="ワンタップで自分好みの食事が届くMeal-Fit" />
            </a>
        </div>
        <div id='issue'>
            <h2 className='text-center my-5 display-5 fw-normal'>
                今日の夜ごはん、どうしますか？
            </h2>
            <Col md={{ span:5, offset:2 }} xs={12} className='my-3'>
                <Row>
                    <Col xs={3}>
                        <img src="/lp_image/issue1.png" className='w-100 rounded-circle' alt='お悩み1' />
                    </Col>
                    <Col xs={9}>
                        <div className="says">
                            <p>今日も仕事がギリギリ！最近コンビニかスーパーで同じものばかり食べてるな…。</p>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col md={{ span:5, offset:5 }} xs={12} className='my-3'>
                <Row>
                    <Col xs={9}>
                        <div className="says-right">
                            <p>子連れで買い物ってハードル高すぎ！育児の合間に料理するからよく失敗しちゃう…。</p>
                        </div>
                    </Col>
                    <Col xs={3}>
                        <img src="/lp_image/issue2.png" className='w-100 rounded-circle' alt='お悩み2' />
                    </Col>
                </Row>
            </Col>         
            <Col md={{ span:5, offset:2 }} xs={12} className='my-3'>
                <Row>
                    <Col xs={3}>
                        <img src="/lp_image/issue3.png" className='w-100 rounded-circle' alt='お悩み3' />
                    </Col>
                    <Col xs={9}>
                        <div className="says">
                            <p>いつも何つくろうって考えてる…。「なんでもいい」はもう聞きたくない！</p>
                        </div>
                    </Col>
                </Row>
            </Col>
        </div>
        <div id='solution' className='lp-bg py-3'>
            <h2 className='text-center my-3 display-5 fw-normal'>
                Meal-Fitならワンタップで夜ごはん問題を解決！
            </h2>
            <Col md={{ span:8, offset:2 }} xs={12} className='my-3'>
                <Row>
                    <Col md={4} xs={12}>
                        <img src="/lp_image/feature1.png" width={300} className='d-block mx-auto mb-3' alt='悩まない' />
                    </Col>
                    <Col md={4} xs={12}>
                        <img src="/lp_image/feature2.png" width={300} className='d-block mx-auto mb-3' alt='作らない' />
                    </Col>
                    <Col md={4} xs={12}>
                        <img src="/lp_image/feature3.png" width={300} className='d-block mx-auto mb-3' alt='健康で美味しい' />
                    </Col>
                </Row>
            </Col>
            <a href='https://lin.ee/VDQnQiA' rel="noopener noreferrer">
                <button type="button" className="btn btn-success btn-lg d-block mx-auto">LINEではじめる</button>
            </a>
        </div>
        <div id='feature' className='py-3'>
            <h2 className='text-center my-5 display-4 fw-normal'>Meal-Fitの特徴</h2>
            <Col md={{ span:8, offset:2 }} xs={12} className='my-3'>
                <Row>
                    <Col md={4} xs={12}>
                        <Card className='my-3'>
                            <Card.Body>
                                <Card.Title className='fw-bold text-center'>薬膳×栄養学で<br />メニューを開発</Card.Title>
                                <Card.Img variant="top" src="/lp_image/feature1.jpeg" />
                                <Card.Text>
                                    薬膳アドバイザー、管理栄養士と一緒にメニューを開発。
                                    栄養バランスを数値化し、美味しく健康的な食事がベースとなっています。
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} xs={12}>
                        <Card className='my-3'>                            
                            <Card.Body>
                                <Card.Title className='fw-bold text-center'>あなた好みの食事<br />で飽きない</Card.Title>
                                <Card.Img variant="top" src="/lp_image/feature2.jpeg" />
                                <Card.Text>
                                    好き嫌い、お悩みの症状を考慮して食材やメニューを調整。<br />
                                    <small>※アレルギーは今後考慮できる体制を作っていきます。</small>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} xs={12}>
                        <Card className='my-3'>
                            <Card.Body>
                                <Card.Title className='fw-bold text-center'>洗い物不要！<br />すぐに食卓へ出せる</Card.Title>
                                <Card.Img variant="top" src="/lp_image/feature3.jpeg" />
                                <Card.Text>
                                    容器は電子レンジOK！カップに入っている冷たい惣菜を取り出して温めれば、すぐに食卓に出すことができます。
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Col>

        </div>

        <div id='item' className='lp-bg py-3'>
            <h2 className='text-center my-3 display-5 fw-normal'>
                販売商品
            </h2>
            <Row>
                <Col md={{span:10, offset:1}} xs={12}>
                    <Row>
                        <Col md={4} xs={12}>
                            <Card className='mb-3'>
                                <Card.Img variant="top" src="/lp_image/separate.png" />
                                <Card.Body>
                                    <Card.Title className='fw-bold text-center'>お弁当大サイズ<br />1.000円</Card.Title>
                                    <Card.Text>
                                        たくさん食べたい方に。最大5品入ったボリューミーなセパレートタイプです。
                                        <ul>
                                            <li>メイン1～2品</li>
                                            <li>副菜2～3品</li>
                                            <li>雑穀ごはん200～250g</li>
                                        </ul>
                                        ※ごはん無しは100円引きとなります。
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} xs={12}>
                            <Card className='mb-3'>
                                <Card.Img variant="top" src="/lp_image/small.png" />
                                <Card.Body>
                                    <Card.Title className='fw-bold text-center'>お弁当小サイズ<br />700円</Card.Title>
                                    <Card.Text>
                                        小食の方やお子さまに。ワンプレートにおにぎりと惣菜が入っています。
                                        <ul>
                                            <li>メイン2品</li>
                                            <li>副菜2～3品</li>
                                            <li>雑穀ごはん200～250g</li>
                                        </ul>
                                        ※ごはん無しは100円引きとなります。
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} xs={12}>
                            <Card className='mb-3'>
                                <Card.Img variant="top" src="/lp_image/oneplate.png" />
                                <Card.Body>
                                    <Card.Title className='fw-bold text-center'>ワンプレート弁当<br />500円</Card.Title>
                                    <Card.Text>
                                        小食の方やお子さまにちょうどいいサイズのお弁当です。
                                        <ul>
                                            <li>メイン1品</li>
                                            <li>副菜2～3品</li>
                                            <li>雑穀おにぎり50g×2個</li>
                                        </ul>
                                        　　　　
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
        <div id='item' className='lp-bg py-3'>
            <a href='https://lin.ee/VDQnQiA' rel="noopener noreferrer">
                <button type="button" className="btn btn-success btn-lg d-block mx-auto mb-5">LINEではじめる</button>
            </a>
        </div>
        <div id='flow'>
            <h2 className='text-center my-5 display-5 fw-normal'>ご利用の流れ</h2>
            <Col md={{ span:8, offset:2 }} xs={12} className='my-3'>
                <Row>
                    <Col md={3} xs={12}>
                        <h3 className='text-center'><Badge bg="success" className='my-2'>STEP1</Badge><br />LINE友達追加</h3>
                        <img src="/lp_image/step1.png" width={200} className='d-block mx-auto mb-3' alt='悩まない' />
                        <p className='m-3'><a href='https://lin.ee/VDQnQiA' rel="noopener noreferrer">LINEで友達追加</a>します。</p>
                    </Col>
                    <Col md={3} xs={12}>
                        <h3 className='text-center'><Badge bg="success" className='my-2'>STEP2</Badge><br />予約・注文</h3>
                        <img src="/lp_image/step2.png" width={200} className='d-block mx-auto mb-3' alt='作らない' />
                        <p className='m-3'>定期的に配信している料理の投稿からワンタップで予約注文してください。販売スケジュールはメニュー欄から確認できます。</p>
                    </Col>
                    <Col md={3} xs={12}>
                        <h3 className='text-center'><Badge bg="success" className='my-2'>STEP3</Badge><br />食事の受け取り</h3>
                        <img src="/lp_image/step3.png" width={200} className='d-block mx-auto mb-3' alt='健康で美味しい' />
                        <p className='m-3'>当日17～19時に販売所で受け渡しします。お届けは1回500円で承っています。</p>
                    </Col>
                    <Col md={3} xs={12}>
                        <h3 className='text-center'><Badge bg="success" className='my-2'>STEP4</Badge><br />感想を送る</h3>
                        <img src="/lp_image/step4.png" width={200} className='d-block mx-auto mb-3' alt='健康で美味しい' />
                        <p className='m-3'>食事の感想をお送りいただけると、次回以降、提供する際に考慮していきます。</p>
                    </Col>
                </Row>
            </Col>
        </div>
        <div id='schedule'>
            <h2 className='text-center my-5 display-5 fw-normal'>販売スケジュール</h2>
            <Row>
                <Col md={{span:8, offset:2}} xs={12}>
                <p className='text-center'>
                    製造分に余裕があれば、当日、販売所で購入することもできます。<br />
                    確実に受け取りたい方は事前予約を<a href='https://lin.ee/VDQnQiA'>LINE</a>からお願いいたします。
                </p>
                <iframe 
                    src={iframeSrc}
                    className="w-100 mb-3"
                    height="400"
                    title="Meal-Fit販売スケジュール"
                    >
                </iframe>
                </Col>
            </Row>
        </div>
        <div id='item' className='lp-bg py-3'>
            <a href='https://lin.ee/VDQnQiA' rel="noopener noreferrer">
                <button type="button" className="btn btn-success btn-lg d-block mx-auto mb-3">LINEではじめる</button>
            </a>
        </div>
        <div id='company'>
        <h3 className='text-center my-5 fw-normal'>運営会社</h3>
        <Row>
            <Col md={{span:8, offset:2}} xs={12}>
                <Table>
                    <tbody>
                        <tr>
                            <th className='p-3'>会社名</th>
                            <td className='p-3'>株式会社ロキア</td>
                        </tr>
                        <tr>
                            <th className='p-3'>住所</th>
                            <td className='p-3'>〒813-0017 福岡県福岡市東区香椎照葉1-1-37</td>
                        </tr>
                        <tr>
                            <th className='p-3'>お問い合わせ</th>
                            <td className='p-3'><a href='mailto:info@rokia.co.jp'>info@rokia.co.jp</a></td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>

        </div>        

    </Container>
    );
}

export default LP;